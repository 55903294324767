<template>
    <div
        class="v-dropdown"
        :class="isOpened ? activeClass : null"
        v-click-outside="hide"
    >
        <div
            class="dropdown__header"
            @click="switchState($event)"
            @click.once="onFirstOpen"
        >
            <slot name="header"/>
        </div>
        <transition :name="animation" tag="fragment">
            <div
                v-if="isOpened"
                class="dropdown__body"
                :style="styles"
                @click="bodyClickHandler($event)"
            >
                <slot name="body"/>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "Dropdown",
        props: {
            activeClass: {
                type: String,
                required: false,
                default: ''
            },
            closeOutside: {
                type: Boolean,
                required: false,
                default: false
            },
            opened: {
                type: Boolean,
                required: false,
                default: false
            },
            styles: {
                type: Object,
                required: false,
                default: null
            },
            animation: {
                type: String,
                required: false,
                default: null
            },
            onFirstOpen: {
                type: Function,
                required: false,
                default: () => false
            }
        },
        data() {
            return {
                isOpened: this.opened,
            }
        },
        methods: {
            bodyClickHandler(e) {
                if (e.target.closest('.dropdown-hide')) {
                    this.switchState();
                }
            },
            switchState(e) {
                if (!e.target.classList.contains('dropdown-hide')) {
                    this.isOpened = !this.isOpened;
                }
            },
            hide() {
                this.$nextTick(() => {
                    if (this.closeOutside) {
                        /* Проверка для того, чтобы дропдаун не закрывался при клике на другие дропдауны */
                        // if (!e.target.offsetParent.className.includes('dropdown')) {
                        this.isOpened = false;
                        // }
                    }
                });
            }
        },
    }
</script>

<style lang="scss">
    .slide-enter, .slide-leave-to{
        transform: scaleY(0);
    }
</style>
